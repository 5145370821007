import zod from 'zod';

const onlyLettersAndNumbersRegex = /^[a-zA-Z0-9 ]*$/;
const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*-]).{8,}$/;
const passwordErrorMessage =
  'Must contain at least 8 characters: 1 Uppercase, 1 Lowercase, 1 Number and 1 Special Case Character (!@#$%^&*-)';

export const emptySchema = zod.object({});

export const resetPasswordSchema = zod.object({
  email: zod.string().min(5, 'Email is required').email({ message: 'Enter valid email' }),
});

export const resetPasswordVerifySchema = zod.object({
  smsCode: zod.string().min(1, 'Field is required'),
  newPassword: zod.string().min(1, 'Password is required').regex(passwordRegex, passwordErrorMessage),
});

export const createPasswordSchema = zod
  .object({
    password: zod.string().min(1, 'Password is required').regex(passwordRegex, passwordErrorMessage),
    confirmPassword: zod.string().min(1, 'Password is required'),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords don't match",
    path: ['confirmPassword'],
  });

export const loginSchema = zod.object({
  email: zod.string().min(1, 'Email is required').email({ message: 'Enter valid email' }),
  password: zod.string().min(1, 'Password is required'),
});

export const signUpSchema = zod.object({
  email: zod.string().min(1, 'Email is required').email({ message: 'Enter valid email' }),
  password: zod.string().min(1, 'Password is required').regex(passwordRegex, passwordErrorMessage),
  agreeTosPrivacy: zod.literal(true),
});

export const notEmptyDigitInput = zod.object({
  digits: zod.string().min(6),
  rememberMe: zod.boolean(),
});

export const notEmptyDigitInputSecurity = zod.object({
  digits: zod.string().min(6),
});

export const phoneNumberSchema = zod.object({
  phoneNumber: zod.string().min(9, 'Phone must contain at least 9 characters'),
});

export const personalInfoSchema = zod.object({
  firstName: zod.string().min(2, 'First name must contain at least 2 characters'),
  lastName: zod.string().min(2, 'Last name must contain at least 2 characters'),
  phoneNumber: zod.string().min(9, 'Phone must contain at least 9 characters'),
  whereDidYouHearAboutUs: zod.string().min(1, 'Please choose one of the options'),
  specify: zod.string().max(50, 'Maximum 50 symbols'),
});

export const organizationSchema = zod.object({
  organizationName: zod.string().min(1),
  webSite: zod.string().refine((value) => value.startsWith('https://') || value.startsWith('www') || value === '', {
    message: 'https:// needs to be the start of the website',
  }),
});

export const additionalInfoSchema = zod.object({
  abn: zod.string().regex(/^(\d\s*){11}$/, 'Must contain exactly 11 characters'),
  entityType: zod.string().min(1),
  accountPurpose: zod.string().min(1),
});

export const yourCustomersSchema = zod.object({
  customers: zod.array(zod.string()).min(1),
  agreeTosPrivacy: zod.literal(true),
});

export const enterAmountSchema = zod.object({
  pay: zod.string(),
  get: zod.string(),
  network: zod.string(),
});

export const paymentSchema = zod.object({
  typeOfPayment: zod.string(),
});

export const scamRiskWarningSchema = zod
  .object({
    confirm: zod.string().transform((value) => value.trim()),
    checkboxes: zod.array(zod.string()).length(5),
  })
  .refine((data) => data.confirm.toUpperCase() === 'CONFIRM');

export const hostedSchema = zod.object({
  get: zod.string().min(1),
  network: zod.string(),
  billInvoice: zod.string().min(1, 'Minimum 1 symbol').max(50, 'Maximum 50 symbols'),
  email: zod.string().min(1, 'Email is required').email({ message: 'Enter valid email' }),
  agree: zod.literal(true),
});

export const hostedAccountingFirmSchema = zod.object({
  accountId: zod.string().min(1),
  get: zod.string().min(1),
  network: zod.string(),
  surname: zod.string().min(1),
  billInvoice: zod.string().min(1, 'Minimum 1 symbol').max(50, 'Maximum 50 symbols'),
  email: zod.string().min(1, 'Email is required').email({ message: 'Enter valid email' }),
  agree: zod.literal(true),
});

export const requiredFileSchema = zod.object({
  document: zod.any().refine((files) => files?.length > 0, 'File is required'),
});

export const metaInfoSchema = zod.object({
  heading: zod.string().min(1).max(50, 'The heading contains too many characters'),
  description: zod.string().min(1).max(300, 'The description contains too many characters'),
});

export const relayPayUrlSchema = zod.object({
  url: zod
    .string()
    .regex(/^\w+\.relaypay.io$/, ' ')
    .max(62, 'The maximum number of characters allowed is 50'),
});

const zodDescriptionSchema = zod
  .string()
  .regex(onlyLettersAndNumbersRegex, "Please don't use special symbols")
  .max(35, 'The maximum number of characters allowed is 35')
  .nullable();

export const sellCryptoAustraliaSchema = zod.object({
  get: zod.string().min(1),
  pay: zod.string(),
  accountName: zod.string().min(1, 'Account Name is required'),
  BSB: zod.string().min(6, 'BSB must contain at least 6 characters'),
  accountNumber: zod
    .string()
    .min(6, 'Account Number must contain at least 6 characters')
    .max(9, 'Account Number must contain maximum 9 symbols'),
  description: zodDescriptionSchema,
  network: zod.string(),
});

export const withdrawCryptoAustraliaSchema = zod.object({
  audAmount: zod
    .string()
    .min(1)
    .refine((value) => parseFloat(value) >= 100, {
      message: 'The minimum referral bonus withdrawal is $100 AUD',
    }),
  bsb: zod.string().min(6, 'BSB must contain at least 6 characters'),
  accountNo: zod
    .string()
    .min(6, 'Account Number must contain at least 6 characters')
    .max(9, 'Account Number must contain maximum 9 symbols'),
});

export const sellCryptoOtherWithRoutingNoSchema = zod.object({
  get: zod.string().min(1),
  pay: zod.string(),
  accountName: zod.string().min(1, 'Account Name is required'),
  accountNumber: zod.string().min(6, 'Account Number must contain at least 6 characters'),
  routingNumber: zod.string(),
  BICSWIFT: zod.string().min(1, 'BIC/SWIFT is required'),
  description: zodDescriptionSchema,
});

export const sellCryptoOtherBankSchema = zod.object({
  get: zod.string().min(1),
  pay: zod.string(),
  accountName: zod.string().min(1, 'Account Name is required'),
  accountNumber: zod.string().min(6, 'Account Number must contain at least 6 characters'),
  BICSWIFT: zod.string().min(1, 'BIC/SWIFT is required'),
  description: zodDescriptionSchema,
});

export const sellCryptoEuroSchema = zod.object({
  get: zod.string().min(1),
  pay: zod.string(),
  country: zod.string(),
  accountName: zod.string().min(1, 'Account Name is required'),
  IBAN: zod.string().min(1),
  BICSWIFT: zod.string().min(1, 'BIC/SWIFT is required'),
  description: zodDescriptionSchema,
});

export const sellCryptoBpaySchema = zod.object({
  get: zod.string().min(1),
  pay: zod.string(),
  network: zod.string(),
  billerCode: zod.string().min(1, 'Biller Code is required'),
  referenceNumber: zod.string().min(4, 'Minimum 4 symbols').max(23, 'Maximum 23 symbols'),
});

export const sellCryptoCreditCardSchema = zod.object({
  get: zod.string().min(1),
  pay: zod.string(),
  network: zod.string(),
  billerCode: zod.string().min(1, 'Biller Code is required'),
  cardNumber: zod
    .string()
    .min(16, 'Card Number must contain 16 characters')
    .max(16, 'Card Number must contain 16 characters'),
});

export const sellCryptoGiftCardsSchema = zod.object({
  get: zod.string().min(1),
  pay: zod.string(),
  network: zod.string(),
});

export const profileLocationSchema = zod
  .object({
    fullAddress: zod.string().min(1),
    countryIso: zod.string(),
    country: zod.string().min(1),
    state: zod.string().min(1),
    town: zod.string().min(1),
    street: zod.string().min(1),
    postcode: zod.string().min(1),
    unitNumber: zod.string(),
    buildingNumber: zod.string(),
  })
  .refine(
    (data) => {
      if (!data.unitNumber && !data.buildingNumber) {
        return false;
      }

      return true;
    },
    {
      message: 'Unit number and building number validation failed.',
    },
  );

export const widgetFormSchema = zod.object({
  fiatAmount: zod
    .string()
    .min(1, 'Please enter at least 1 digit')

    .refine(
      (data) => {
        if (+data === 0) {
          return false;
        }

        return true;
      },
      {
        message: 'Too small amount',
      },
    )
    .refine(
      (data) => {
        if (+data > 6000000) {
          return false;
        }

        return true;
      },
      {
        message: 'Too large amount',
      },
    ),

  cryptoAmount: zod.string(),
  fiatCurrency: zod.string(),
});
